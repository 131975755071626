import { Divider, Grid, Hidden, Paper, Typography } from '@material-ui/core';
import { Link, graphql } from 'gatsby';
import { blue, grey, orange, red, teal } from '@material-ui/core/colors';

import Bio from '../components/Bio';
import Layout from '../components/Layout';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import PageTitle from '../components/PageTitle';
import React from 'react';
import SEO from '../components/seo';
import Section from '../components/Section';
import SocialProfiles from '../components/SocialProfiles';
import { SubscriptionForm } from '../components/SubscriptionForm';
import { TwitterShareButton } from 'react-twitter-embed';
import TwitterTweetEmbed from '../components/TwitterPost';

const History = ({ previous, next }) => (
  <>
    <Typography gutterBottom variant={'overline'} color={'textSecondary'}>
      Read more
    </Typography>
    <Grid container spacing={8}>
      <Grid item xs={6}>
        {previous && (
          <Typography>
            <Link to={previous.fields.slug} rel={'prev'}>
              {previous.frontmatter.title}
            </Link>
          </Typography>
        )}
      </Grid>
      <Grid item xs={6}>
        {next && (
          <Typography>
            <Link to={next.fields.slug} rel={'next'}>
              {next.frontmatter.title}
            </Link>
          </Typography>
        )}
      </Grid>
    </Grid>
  </>
);

function PageTemplate(
  { data: { mdx, site, allMailchimpList }, pageContext },
  location
) {
  const titleOfWebsite = site.siteMetadata.title;
  const titleOfPost = mdx.frontmatter.title;
  const post = mdx;
  const { previous, next } = pageContext;
  const countOfSubscribers = allMailchimpList.edges[0].node.stats.member_count;
  const hasCover = !!post.frontmatter.cover;

  return (
    <Layout
      title={titleOfWebsite}
      location={location}
      // cover={mdx.frontmatter.cover}
      fullWidth
    >
      <SEO
        cover={hasCover ? post.frontmatter.cover : null}
        title={post.frontmatter.title}
        description={post.excerpt}
      />
      {!!hasCover ? (
        <>
          <br />
        </>
      ) : null}
      <Section>
        <PageTitle>{titleOfPost}</PageTitle>

        <Grid container>
          <Grid item xs={6}>
            <Typography color={'textSecondary'} gutterBottom>
              {post.frontmatter.date}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align={'right'} color={'textSecondary'} gutterBottom>
              by <b>Rohov Dmytro</b>
            </Typography>
          </Grid>
        </Grid>
      </Section>
      <Divider />
      <Section horizontalPadding={0}>
        <div style={{ maxWidth: '640px' }}>
          <MDXProvider
            components={{
              a: (props) => <a style={{ color: blue[400] }} {...props} />,
              img: (props) => (
                <div style={{ margin: '16px 0' }}>
                  <img {...props} />
                </div>
              ),
              h1: (props) => (
                <Typography variant={'h4'} gutterBottom {...props} />
              ),

              h2: (props) => (
                <Typography variant={'h5'} gutterBottom {...props} />
              ),
              h3: (props) => (
                <Typography variant={'h6'} gutterBottom {...props} />
              ),
              blockquote: (props) => (
                <Typography
                  paragraph
                  style={{
                    margin: '16px 0px',
                    borderLeft: `3px solid ${red[600]}`,
                    padding: '0px 16px 0px 16px',
                    color: grey[400],
                  }}
                  {...props}
                />
              ),
              p: (props) => (
                <Typography variant={'body1'} paragraph {...props} />
              ),
            }}
          >
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </div>
      </Section>
      <Section horizontalPadding={0} color={red[900]}>
        <Typography gutterBottom variant={'overline'} color={'textSecondary'}>
          Subscribe!
        </Typography>
        <SubscriptionForm countOfSubscribers={countOfSubscribers} />
        <br />
        <br />
      </Section>
      <Section>
        <Typography gutterBottom variant={'overline'} color={'textSecondary'}>
          About the author
        </Typography>
        <Bio />
        <br />
        <br />
        <Typography gutterBottom variant={'overline'} color={'textSecondary'}>
          Connect elsewhere
        </Typography>
        <SocialProfiles />
        <br />
        {previous || next ? <History previous={previous} next={next} /> : null}
      </Section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMailchimpList {
      edges {
        node {
          id
          stats {
            member_count
          }
        }
      }
    }
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title

        date(formatString: "MMMM DD, YYYY")
        cover {
          publicURL
          childImageSharp {
            sizes(maxWidth: 2000) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
      body
    }
  }
`;

export default PageTemplate;
