import React from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';

export default ({ linkToTwitter }) => {
  const id = linkToTwitter.match(/status\/(\d+)/)[1];

  return (
    <>
      <TwitterTweetEmbed tweetId={id} options={{ height: 400 }} />
    </>
  );
};
