import { Button, Grid, Typography } from '@material-ui/core';
import { FacebookProvider, Like, Page } from 'react-facebook';

import React from 'react';

export default () => (
  <Grid container spacing={1}>
    <Grid item xs={6}>
      <FacebookProvider appId='487993741300188'>
        <Like
          href={'https://www.facebook.com/rg.swing.dancer'}
          colorScheme={'dark'}
          layout={'button_count'}
          size={'large'}
          showFaces={false}
          colorScheme={'dark'}
        />
      </FacebookProvider>
    </Grid>
    <Grid item xs={6}>
      <a href={'https://twitter.com/rohovdmytro'} target={'_blank'}>
        <Button fullWidth variant={'outlined'}>
          Join on Twitter
        </Button>
      </a>
    </Grid>
    <Grid item xs={6}>
      <a href={'https://dev.to/rohovdmytro'} target={'_blank'}>
        <Button fullWidth variant={'outlined'}>
          Join on Dev.to
        </Button>
      </a>
    </Grid>
    <Grid item xs={6}>
      <a href={'https://medium.com/@rohovdmytro'} target={'_blank'}>
        <Button fullWidth variant={'outlined'}>
          Join on Medium
        </Button>
      </a>
    </Grid>
  </Grid>
);
